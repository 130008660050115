<template>
  <b-card>
    <b-overlay
      :show="isLoading"
      opacity="0.70"
      variant="transparent"
      rounded="sm"
    >
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="2"
          xl="1"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="require('@/assets/images/pages/book.svg')"
              class="bg-avatar bg-color-avatar"
              size="80px"
              rounded
            />
          </div>
          <b-row>
            <b-button
              v-show="!showUpdate"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-primary"
              block
              size="sm"
              @click="updateDetail(bookDetails.id)"
            >
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <small>Editar</small>
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-modal.modal-delete-book
              variant="outline-danger"
              block
              size="sm"
            >
              <feather-icon
                icon="ArchiveIcon"
                class="mr-50"
              />
              <small>Arquivar</small>
            </b-button>
          </b-row>
        </b-col>

        <!-- Modal delete -->
        <b-modal
          id="modal-delete-book"
          ref="modal-delete-book"
          ok-variant="danger"  
          modal-class="modal-danger"
          centered
          title="Gerenciar licenças"
        >
          <b-card-text>
            <b>Deseja arquivar este livro?</b>
          </b-card-text>
          <template #modal-footer>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mt-2"
                variant="outline-danger"
                block
                @click="deleteBook"
              >
                <feather-icon
                  icon="ArchiveIcon"
                  class="mr-50"
                />
                Arquivar
              </b-button>
            </b-col>
          </template>
        </b-modal>

        <!-- Right Col: Table -->
        <b-col lg="5">
          <table 
            v-if="bookDetails" 
            class="mt-2 mt-xl-0 w-98" 
          >
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="PenToolIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Titulo:</span>
              </th>
              <td class="pb-50 text-capitalize font-weight-bold">
                {{ bookDetails.name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UsersIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Autores:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ bookDetails.author }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ListIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Categoria:</span>
              </th>
              <td
                v-if="bookDetails.type == 'student'"
                class="pb-50"
              >
                Aluno
              </td>
              <td
                v-else
                class="pb-50"
              >
                Professor
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Edição:</span>
              </th>
              <td class="pb-50">
                {{ bookDetails.edition }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookmarkIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">ISBN</span>
              </th>
              <td class="pb-50">
                {{ bookDetails.isbn }}
              </td>
            </tr>
          </table>
        </b-col>
        <!-- Endereço -->
        <b-col>
          <table 
            v-if="bookDetails" 
            class="mt-2 mt-xl-0 w-98"
          >
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Data de publicação:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ bookDetails.publication_date | dateFormat }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Data de cadastro:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ bookDetails.created_at | dateFormat }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <hr>
      <div v-show="!showUpdate">
        <b-row>
          <b-col md="5">
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Exibir"
                  label-for="h-exibir"
                  label-cols-md="4"
                  class="text-muted"
                >
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    class="form-content"
                    :options="perPageOptions"
                    :disabled="isLoading"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <div class="col-md">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="search"
                placeholder="Pesquisar"
                debounce="800"
                type="search"
                size="sm"
                :disabled="isLoading"
              />
            </b-input-group>
          </div>
          <b-col
            md="2"
          >
            <b-button
              v-b-toggle.sidebar-create-lessons
              variant="relief-primary"
              size="sm"
              block
            >
              Nova Aula
            </b-button>
          </b-col>
          <b-col md="2">
            <b-button 
              v-b-toggle.sidebar-select-lessons
              variant="outline-primary"
              size="sm"
              block
            >
              + aulas existentes
            </b-button>
          </b-col>
        </b-row>
        <b-table-simple
          v-if="bookDetails"
          id="tableBook"
          striped
          stacked="md"
          class="mt-2 responsive text-center"
        >
          <b-thead>
            <b-tr class="text-center">
              <b-th>#</b-th>
              <b-th>Aulas</b-th>
              <b-th>Objetivo</b-th>
              <b-th>
                Ações
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(tr, indextr) in bookDetails.lessons"
              :key="indextr"
            >
              <b-td>
                <!--<b-button-group>
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" variant="outline-primary"
                    size="sm" :disabled="indextr == 0 && tr.order == 1" @click="newOrderUp(tr.id, tr.order)">
                    <feather-icon icon="ChevronUpIcon" />
                  </b-button>
                  <b-button disabled>
                    {{ tr.order }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon"
                    variant="outline-primary"
                    size="sm"
                    :disabled="indextr == bookDetails.lessons.length - 1"
                    @click="newOrderDown(tr.id, tr.order)"
                  >
                    <feather-icon icon="ChevronDownIcon" />
                  </b-button>
                </b-button-group>-->

                <b-button-group>
                  <b-button disabled>
                    {{ tr.order }}
                  </b-button>
                  <b-form-input v-model="orderBook" class="btn-icon" placeholder="Ordem" @click="lesson_id_order = tr.id"/>
                </b-button-group>
              </b-td>
              <b-td>
                {{ tr.name }}
              </b-td>
              <b-td>
                {{ tr.objective }}
              </b-td>
              <b-td>
                <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-dark" class="btn-icon rounded-circle"
                  :to="{ path: '/livro/aula/' + tr.id}">
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-row class="mt-1">
          <b-col md="10">
            <b-row>
              <b-col
                v-show="totalRows > 10"
                md="5"
              >
                Exibindo {{ perPage }} de {{ totalRows }} registros
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="tableBook"
              class="mt-1"
              @change="handlePageChange"
            />
          </b-col>
        </b-row>
      </div>
      <div v-show="showUpdate">
        <h3 class="text-center mb-2">
          Editar Livro
        </h3>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Titulo:"
              label-for="i-titulo"
            >
              <b-form-input
                id="i-titulo"
                v-model="updateBook.name"
                placeholder="Ex: 9º Ano"
              />
            </b-form-group>
            <b-form-group
              label="Categoria:"
              label-for="i-categ"
            >
              <b-form-select
                v-model="updateBook.type"
                :options="optionsCategories"
              />
            </b-form-group>
            <b-form-group
              label="Autores:"
              label-for="i-autores"
            >
              <b-form-input
                id="i-autores"
                v-model="updateBook.author"
                placeholder="Ex: Nomes dos autores"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Data de publicação:"
              label-for="i-public"
            >
              <b-form-datepicker
                id="example-datepicker"
                v-model="updateBook.publication_date"
                placeholder="Data de publicação"
                class="mb-1"
              />
            </b-form-group>
            <b-form-group
              label="Edição:"
              label-for="i-edicao"
            >
              <b-form-input
                id="i-edicao"
                v-model="updateBook.edition"
                placeholder="Ex: 1º Edição"
              />
            </b-form-group>
            <b-form-group
              label="ISBN:"
              label-for="i-esbn"
            >
              <b-form-input
                id="i-esbn"
                v-model="updateBook.isbn"
                placeholder="Ex: 978-65-81751-04-3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-primary"
              block
              @click="updateBooks"
            >
              <feather-icon icon="Edit2Icon" /> Editar
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              block
              @click="cancelUpdate"
            >
              <feather-icon icon="XIcon" /> Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-sidebar
        id="sidebar-create-lessons"
        ref="sidebar-create-lessons"
        shadow
        width="750px"
        right
        bg-variant="white"
        backdrop
        title="Cadastrar nova aula"
      >
        <div v-if="lessonData">
          <validation-observer ref="simpleRules">
            <!-- Form create Lesson -->
            <!-- Name -->
            <b-form-group
              label="Titulo da aula:"
              label-for="i-titulo"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="titulo"
                rules="required"
              >
                <b-form-input
                  id="i-titulo"
                  v-model="lessonData.name"
                  placeholder="Ex: Aula 1 - Estruturas"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Module Id -->
            <b-form-group
              label="Selecione um módulo:"
              label-for="i-modulo"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="modulo"
                rules="required"
              >
                <b-form-select
                  id="module"
                  v-model="lessonData.module_id"
                  text-field="name"
                  value-field="id"
                  :options="modules"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Objective -->
            <b-form-group
              label="Objetivo:"
              label-for="i-objetivo"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="objetivo"
                rules="required"
              >
                <b-form-textarea
                  id="i-objetivo"
                  v-model="lessonData.objective"
                  placeholder="Informe o objetivo da aula"
                  rows="3"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Materiais necessários:" class="pl-1 pr-1">
              <b-overlay :show="isLoadingQuill" opacity="0.70" variant="transparent" rounded="sm">
                <quill-editor 
                  v-model="lessonData.supplies_needed" 
                  :options="editorOption"
                >
                  Informe os items aqui
                </quill-editor>
              </b-overlay>
            </b-form-group>

            <!-- Competence BNCC -->
            <b-form-group
              label="Competências BNCC:"
              label-for="i-bncc"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="BNCC"
                rules="required"
              >
                <v-select
                  v-model="lessonData.competences"
                  size="lg"
                  multiple
                  label="code"
                  :options="compentences"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Ability -->
            <b-form-group
              label="Habilidades:"
              label-for="i-habilidades"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="habilidades"
                rules="required"
              >
                <v-select
                  v-model="lessonData.abilities"
                  size="lg"
                  multiple
                  label="code"
                  :options="abilities"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Video:" label-for="i-video"  class="pl-1 pr-1">
              <b-form-input id="i-video" placeholder="Ex: www.youtube.com.br" v-model="lessonData.video_url" />
            </b-form-group>
          </validation-observer>
          <hr>
          <!-- Complementary Material -->
          <h6 class="pl-1 pr-1 text-muted">
            MATERIAL COMPLEMENTAR
          </h6>
          <div>
            <b-form
              enctype="multipart/form-data"
              method="post"
            >
              <b-form-input
                class="hidden"
                name="_token"
                :value="csrf"
              />

              <!-- Name Material -->
              <b-form-group
                label="Titulo do material:"
                label-for="i-material-title"
                class="pl-1 pr-1" 
              >
                <b-form-input
                  id="i-material-title"
                  v-model="support_materials.name"
                  placeholder="Ex: Apostila"
                />
              </b-form-group>
              <!-- Description Material Complementary -->
              <b-form-group
                label="Autor:"
                label-for="i-material-autor"
                class="pl-1 pr-1"
              >
                <b-form-input
                  v-model="support_materials.description"
                />
              </b-form-group>
              <b-form-group
                label="Tipo:"
                label-for="i-material-tipo"
                class="pl-1 pr-1"
              >
                <b-row>
                  <b-col lg="1">
                    <b-form-checkbox
                      v-model="fileOrLink"
                      checked="true"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="PaperclipIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="Share2Icon" />
                      </span>
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <span class="switch-icon-right">
                      <feather-icon icon="PaperclipIcon" />
                      Arquivo
                    </span>
                  </b-col>
                  <b-col>
                    <span class="switch-icon-right">
                      <feather-icon icon="Share2Icon" />
                      Link
                    </span>
                  </b-col>
                </b-row>
              </b-form-group>
              <!-- Upload File Complementary -->
              <b-form-group
                label="Material Complementar:"
                label-for="i-material-complementar"
                class="pl-1 pr-1"
              >
                <b-form-file
                  v-if="fileOrLink == true"
                  v-model="file"
                  placeholder="Selecione um arquivo"
                  type="file"
                  name="file"
                />
                <b-form-input
                  v-if="fileOrLink == false"
                  v-model="support_materials.link"
                  placeholder="Insira o link do material"
                />
              </b-form-group>
              <b-row>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  block
                  @click="storeSupportMaterial"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Incluir Material</span>
                </b-button>
              </b-row>
              <b-row>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  v-b-modal.modal-show-support
                  variant="flat-primary"
                  block
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Ver materiais adicionados</span>
                </b-button>
              </b-row>
            </b-form>
          </div>
        </div>
        <b-row class="p-1">
          <b-col
            lg="6"
            sm="6"
            class="mr-1"
          >
            <!-- Save -->
            <b-button
              block
              variant="relief-primary"
              :disabled="lessonData.name == null"
              @click="storeLesson"
            >
              Salvar
            </b-button>
          </b-col>
          <b-col>
            <!-- Save and Exit -->
            <b-button 
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              block
              @click="closeLesson"
            >
              Fechar
            </b-button>
          </b-col>
        </b-row>
      </b-sidebar>
      <select-lessons type="update" />
      <b-modal
        id="modal-show-support"
        ref="modal-show-support"
        cancel-variant="outline-secondary"
        cancel-title="Fechar"
        centered
        title="Materiais adicionados"
      >
        <p>
          <b-row
            v-for="(tr, indextr) in supportLocalMaterials"
            :key="indextr"
          >
            <b-col md="9">
              {{ tr.name }}
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="deleteSupport(tr.id, indextr)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-col>
          </b-row>
        </p>
      </b-modal>
    </b-overlay>
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import SelectLessons from "./SelectLessons.vue";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCard,
    SelectLessons,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    draggable,
    Prism,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,
      isLoadingStore: false,

      showUpdate: false,
      bookDetails: null,

      teste: null,

      updateBook: {
        id: null,
        name: null,
        author: null,
        type: null,
        publication_date: null,
        edition: null,
        isbn: null,
      },

      lesson_id_order: null,
      orderBook: null,

      fileOrLink: true,

      supportLocalMaterials: [],
      support_materials: {
        name: null,
        description: null,
      },
      file: null,

      drag: false,

      lessonData: {
        name: null,
        objective: null,
        module_id: null,
        abilities: [],
        competences: [],
        abilities: [],
        support_materials: [],
        video_url: null,
        supplies_needed: null,
      },

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',

      updateModel: null,

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],

      optionsCategories: [
        { value: 'student', text: 'Aluno' },
        { value: 'teacher', text: 'Professor' }
      ],
      isLoadingQuill: false,

      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{ 'indent': '-1'}, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'font': [] }],
              [{ 'align': [] }],
            ],
          }
        }
      },
    }
  },
  computed: {
    ...mapState("books", ["books"]),
    ...mapState("compentences", ["compentences"]),
    ...mapState("modules", ["modules"]),
    ...mapState("abilities", ["abilities"]),
    ...mapState("supportMaterials", ["supportMaterials"]),
    ...mapState("lessons", ["lessons"]),
  },
  created() {
    this.showBook()
    this.fetchCompentences()
    this.fetchAbility()
    this.fetchModules()
    if (!this.csrf) {
      console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
    }
  },
  methods: {
    ...mapActions("books", ["fetchDetails", "update"]),
    ...mapActions("supportMaterials", ["store", "delete"]),

    closeLesson() {
      this.$refs['sidebar-create-lessons'].hide()
    },

    // saveLocal(){
    //   // localStorage.removeItem('book_id')
    //   localStorage.setItem(
    //     "book_id", this.$route.params.id_book
    //   )
    // },

    backBook() {
      this.$router.push("/livros")
    },

    newOrderUp(id, order) {
      let new_order = --order;
      this.reorderLessons(id, new_order);
    },

    newOrderDown(id, order) {
      let new_order = ++order;
      this.reorderLessons(id, new_order);
    },

    reorderLessons(id, new_order) {
      this.isLoading = true
      this.$store
        .dispatch("books/reorderLessonBook", {
          lesson_id: id,
          new_order: new_order
        })
        .then((response) => {
          this.totalRows = response.total
        })
        .finally(() => {
          this.isLoading = false
          this.orderBook = null
          this.lesson_id_order = null
        })
    },

    fetchCompentences() {
      this.$store
        .dispatch("compentences/fetchCompentence", {
          simple_list: true
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    fetchAbility() {
      this.$store
        .dispatch("abilities/fetchAbility", {
          simple_list: true
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    fetchModules(paginated = false) {
      this.isLoading = true;
      this.$store
        .dispatch("modules/fetchModules", {
          paginate: paginated,
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    storeSupportMaterial() {
      this.isLoadingStore = true
      this.store({
        ...this.support_materials,
        file: this.file,
      })
        .then((val) => {
          this.lessonData.support_materials.push({
            id: val.id,
          })
          this.supportLocalMaterials.push({
            id: val.id,
            name: val.name,
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Material adicionado com sucesso!',
              icon: 'BookIcon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            },
          })

          this.support_materials = []
          this.file = null
        })
        .finally(() => {
          this.isLoadingStore = false
        });
    },

    deleteSupport(id, index) {
      let data = id
      this.delete(
        data
      )
        .then(() => {
          this.supportLocalMaterials.splice(index, 1)
          this.lessonData.support_materials.splice(index, 1)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            },
          })
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {

        });
    },

    storeLesson(value) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let book_ids = this.$route.params.id_book;
          if (localStorage.getItem('book_id')) {
            book_ids = localStorage.getItem('book_id')
          }
          // eslint-disable-next-line
          this.$store.dispatch("lessons/store", {
            ...this.lessonData,
            book_id: book_ids
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })

              this.lessonData.name = null
              this.lessonData.objective = null
              this.lessonData.supplies_needed = null
              this.lessonData.module_id = null
              this.lessonData.abilities = []
              this.lessonData.competences = []
              this.lessonData.support_materials = []
              this.lessonData.video_url = null
              this.support_materials = []
              this.supportLocalMaterials = []

              this.$refs['sidebar-create-lessons'].hide()

            })
            .finally(() => {
              this.showBook()
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })

    },

    deleteBook(id) {
      let book_ids = this.$route.params.id_book;
      if (localStorage.getItem('book_id')) {
        book_ids = localStorage.getItem('book_id')
      }
      let data = book_ids
      this.$store.dispatch("books/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Arquivado com sucesso!',
              icon: 'ArchiveIcon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
        })
        .finally(() => {
          this.$router.push("/livros")
        })
    },

    showBook(paginated = true, page = 1) {
      this.isLoading = true
      let book_ids = this.$route.params.id_book;
      if (localStorage.getItem('book_id')) {
        book_ids = localStorage.getItem('book_id')
      }
      this.$store
        .dispatch("books/fetchDetails", {
          book_id: book_ids,
          paginate: paginated,
          page: page,
          onlyLessons: true,
          filterLessons: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          localStorage.removeItem('typeBook')
          localStorage.setItem(
            "typeBook", response.type
          )
          this.bookDetails = response
          this.totalRows = response.total
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    updateDetail(id) {
      let book_ids = this.$route.params.id_book;
      if (localStorage.getItem('book_id')) {
        book_ids = localStorage.getItem('book_id')
      }
      this.$store
        .dispatch("books/fetchDetails", {
          book_id: book_ids
        })
        .then((response) => {
          this.showUpdate = true
          this.updateBook.id = response.id
          this.updateBook.name = response.name
          this.updateBook.author = response.author
          this.updateBook.type = response.type
          this.updateBook.publication_date = response.publication_date
          this.updateBook.edition = response.edition
          this.updateBook.isbn = response.isbn
        })
    },

    updateBooks() {
      this.update({
        ...this.updateBook
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Atualizado com sucesso!',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            },
          })
        })
        .finally(() => {
          this.showUpdate = false
          this.showBook()
        });
    },

    cancelUpdate() {
      this.showUpdate = false
    },

    handlePageChange(value) {
      this.showBook(true, value);
    },
  },
  watch: {
    perPage() {
      this.showBook()
    },
    search() {
      this.currentPage = 1;
      this.showBook(true, 1);
    },
    orderBook(value){
      if(value)
        this.reorderLessons(this.lesson_id_order, value)
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.bg-color-avatar {
  background: none !important;
}

.style-draggable {
  width: 100%;
  display: inline-block;
  background: #ff0d0d
}
</style>
