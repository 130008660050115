<template>
  <div>
    <b-sidebar
      id="sidebar-select-lessons"
      shadow
      width="750px"
      right
      bg-variant="white"
      backdrop
      title="Selecione as aulas na lista a baixo:"
      ref="sidebar-select-lessons"
    >
    {{ selectionType }}
      <div style="width: 100%; height: 80vh; overflow-y: scroll;">
        <!--<validation-observer ref="simpleRules">-->
        <!-- Form create Lesson -->
        <!-- Name -->
        <b-form-group
          label="Selecione as aulas na ordem que devem aparecer no livro:"
          label-for="i-lesson"
          class="pl-1 pr-1"
        >
          <validation-provider
            #default="{ errors }"
            name="lesson"
            rules="required"
          >
          <div v-for="(item, index) in book_lessons" :key="index">
          <b-form-checkbox  
          :value="item.id"
          class="ml-1 mt-1"
          v-model="lessons"
          >
           {{ item.book }} | {{ item.book_type }} | {{ item.order }} {{ item.name }}
          </b-form-checkbox>
          </div>
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider>
        </b-form-group>
        
        <!--</validation-observer>-->
        <hr>
      </div>
      <b-row class="p-1">
        <b-col lg="4" sm="4" class="mr-1">
          <!-- Save -->
          <b-button block variant="relief-primary" @click="storeLesson(value = 1)" :disabled="lessons.length == 0">
            Salvar
          </b-button>
        </b-col>
        <b-col>
          <!-- Exit -->
          <b-button 
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="closeSelectLesson()"
            block
          >
            Fechar
          </b-button>
        </b-col>
      </b-row>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  props: {
   type: {
    type: String,
    default: 'store'
   }
  },
  components: {
    BButton,
    BSidebar,
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {

      isLoadingStore: false,

      lessons: [],
      dataBook: null,

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
      selectionType: this.type 
    }
  },
  computed: {
    ...mapState("books", ["book_lessons"]),
  },
  methods: {
    closeSelectLesson(){
      this.$refs['sidebar-select-lessons'].hide()
    },
    // Loading Compentence - Using Select Compentence
    fetchBookLessons() {
      this.$store.dispatch("books/fetchBookLessons")
    },
    // Store Lesson -  Save LocalStorage
    storeLesson(value) {
          if(this.type == 'update'){
            if (localStorage.getItem('book_id')) {
              this.$store.dispatch("books/updateWithExistingLessons", {
                book_id: localStorage.getItem('book_id'),
                lessons_to_add: this.lessons
              })  
            }
          }else{
            this.dataBook = JSON.parse(localStorage.book)

            this.dataBook.lessons = this.lessons

            this.$store.dispatch("books/storeWithExistingLessons", this.dataBook)
          }

          // Loading table
          this.$emit("loadingTable", 
          {
            active: true,
          });
          // Close Sidebar
          if(value == 1){
            this.$refs['sidebar-select-lessons'].hide()
          } 
          // Clear Form
          this.lessons = []
          localStorage.removeItem(["book"]);
          this.$router.push("/livros")
    },
  },
  created() {
     this.fetchBookLessons();
     if (! this.csrf) {
      console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
    }
  },
  watch: {
  }
}
</script>
<style scoped>
  .margin-tag{
    margin-bottom: 1px !important;
    margin-right: 1px !important;
  }
</style>
